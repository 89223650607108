import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { graphql } from 'gatsby';
import Seo from '../components/SEO/seo';
import Layout from '../components/LayoutPlainText';
export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
export const _frontmatter = {
  "title": "Privacy Policy",
  "path": "/privacy-policy"
};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Privacy Statement`}</h1>
    <h4>{`Version 1.6 | May 10, 2022`}</h4>
    <p>{`You must be curious how we handle your personal data. In this Privacy Statement we explain how we handle your personal data and why we do so. We believe it's important that you check this and understand our statement. Any questions left? Reach out out to us via `}<a parentName="p" {...{
        "href": "mailto:privacy@flowyour.money"
      }}>{`privacy@flowyour.money`}</a></p>
    <p>{`Our principles are:`}</p>
    <ul>
      <li parentName="ul">{`We will keep your personal data safe and private at all times`}</li>
      <li parentName="ul">{`We will not sell your data`}</li>
      <li parentName="ul">{`We always give you the option to adjust your marketing preferences`}</li>
    </ul>
    <h2>{`1. Introduction`}</h2>
    <p>{`This is the Privacy Statement of Flow Money Automation B.V. and we do our best to help you get a grip on your financial affairs. In this Privacy Statement we describe which personal data we process and why we need it. We want to be clear and transparent in this. We have set up our company in such a way that your privacy is always guaranteed first according to the principles of privacy by design and privacy by default. Flow has been assessed by an independent auditor and is ISO27001 certified. This is the standard in information security. In this way we can demonstrate that information security is an important element at Flow. Every year we are assessed on this by independent auditors who keep us sharp. `}<a parentName="p" {...{
        "href": "/ISO-IEC_27001-ENG-10000319441-MSC-UKAS-NLD-3-20241129.pdf"
      }}>{`You can find our certificate here`}</a>{`.`}</p>
    <p>{`We shall among others:`}</p>
    <ul>
      <li parentName="ul">{`Limit the use of your personal information to the minimum necessary to run Our Services`}</li>
      <li parentName="ul">{`Continuously inform us and let us advise on the obligations under privacy legislation`}</li>
      <li parentName="ul">{`Raise awareness and train involved staff to ensure your privacy`}</li>
      <li parentName="ul">{`Collaborate with the national regulators (AFM, DNB, AP, FIOD)`}</li>
      <li parentName="ul">{`Have strict security standards and procedures to prevent unauthorized access to your data by anyone, including our staff`}</li>
      <li parentName="ul">{`Only work with partners who comply with our strict information security policy so that we can guarantee your privacy`}</li>
    </ul>
    <p>{`We reserve the right to unilaterally amend our Privacy Statement. You may not be able to use our services until you have accepted the latest version of our Privacy Statement. We will notify you when there are changes to the Privacy Policy. The latest version can always be viewed via: `}<a parentName="p" {...{
        "href": "https://flowyour.money/privacy"
      }}>{`https://flowyour.money/privacy`}</a>{`.`}</p>
    <h2>{`2. About us`}</h2>
    <p>{`Flow is responsible for processing your personal data. We take this job very seriously. And of course you want to know who we are. Here you have our data:`}</p>
    <p>{`Flow Money Automation B.V.
Riperwei 54
8406 AK Tijnje
Chamber of Commerce: 72829796
E-mail: `}<a parentName="p" {...{
        "href": "mailto:hi@flowyour.money"
      }}>{`hi@flowyour.money`}</a></p>
    <p>{`Flow has a so-called "PSD2 license". This is a license from the Dutch Central Bank with which we can offer our payment services. PSD2 stands for "Payment Services Directive 2" and is a guideline that ensures that European consumers can ask their bank to share data with companies such as Flow, in a safe and responsible manner. From July 13, 2020, Flow has a license to view your account information (Service 8) and to perform payment initiations (Service 7). You can find us in the DNB Register of Payment Institutions under number: `}<a parentName="p" {...{
        "href": "https://www.dnb.nl/openbaar-register/registerdetailpagina/?registerCode=WFTBI&relationNumber=R166735"
      }}>{`R166735`}</a></p>
    <h2>{`3. Cookies`}</h2>
    <p>{`We use cookies to distinguish you from other visitors to the Site. This way we get a better picture of our visitors and we can improve the user experience of the Site. Would you like to know more about the cookies and our purpose with them? `}<a parentName="p" {...{
        "href": "https://flowyour.money/cookies"
      }}>{`Read our Cookie Statement`}</a>{`.`}</p>
    <h2>{`4. Type of data we collect`}</h2>
    <p>{`We collect and process data about you. There are several reasons why we process this information. Below is an explanation of what for, why and how long we keep the information:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`ID data`}</strong>{`: Personal details (name, date of birth, place of birth, gender), ID document, address, company details (Chamber of Commerce number, UBO information)`}</li>
      <li parentName="ul"><strong parentName="li">{`Communication data`}</strong>{`: Social media profile, email address, mobile phone number`}</li>
      <li parentName="ul"><strong parentName="li">{`Identification data`}</strong>{`: Your email address, mobile phone number`}</li>
      <li parentName="ul"><strong parentName="li">{`Bank information`}</strong>{`: Details of your bank account, linked banks, your name known to the bank, IBAN number (s), balances`}</li>
      <li parentName="ul"><strong parentName="li">{`Transaction information`}</strong>{`: Per transaction (date and time, transaction amount, description (s), IBAN number (s), transaction attachments, counterparty (s)) and general (Balances)`}</li>
      <li parentName="ul"><strong parentName="li">{`Technical information`}</strong>{`: A unique identification number, IP address, time zone setting, operating system and platform, device information, session information, URLs you view on our Site, errors, duration of your visit, page interaction information (scroll behavior, mouse clicks)`}</li>
      <li parentName="ul"><strong parentName="li">{`Anti Money Laundering (AML)`}</strong>{`: Background checks, transaction analysis and payment initiations`}</li>
    </ul>
    <h4>{`Data overview`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`What?`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Purpose?`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Basis?`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`When?`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`ID data`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`- Identification`}<br />{`- Provide our services`}<br />{`- App usage analysis`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`- Legal obligation`}<br />{`- Permission`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`- When connecting the App to your bank`}<br />{`- When using the App`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Communication data`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`- Provide our services`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`- Legitimate interest`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`- By communicating with Flow`}<br />{`- During registration`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Identification details`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`- Identification`}<br />{`- Provide our services`}<br />{`- Communication`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`- Legitimate interest`}<br />{`- Execution of the Agreement`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`- During registration`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Bank information`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`- Provide our services`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`- Permission`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`- While connecting the App to your bank`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Transaction information`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`- Provide our services`}<br />{`- Against money laundering and terrorist financing`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`- Permission`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`- While connecting the App with your bank`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Technical Information`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`- Our services`}<br />{`- App usage analysis`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`- Execution of the Agreement`}<br />{`- Permission`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`- While using the Site and App`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Anti Money Laundering (AML)`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`- Against money laundering and terrorist financing`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`- Legal obligation`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`- After verifying your ID`}<br />{`- With every incoming transaction`}<br />{`- At every payment initiation`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`5. Storing information`}</h2>
    <p>{`The data we collect (ID data, communication data, identification data, bank information, transaction information, technical information, anti money laundering) is sent to and stored at a location in the European Economic Area (“EEA”): Amazon Web Services in Frankfurt, Germany. We only work with partners who meet the security requirements and comply with our Privacy Statement.`}</p>
    <ul>
      <li parentName="ul">{`We store the information we collect on secure servers. Sending encrypted data is done through Transport Layer Security technology (“TLS”).`}</li>
      <li parentName="ul">{`We have taken appropriate (technical and organizational) measures to protect your information. We protect our equipment with passwords, security procedures such as two-step verification and encryption of the storage medium.`}</li>
      <li parentName="ul">{`Much of the storage of the data is legally required under the Wwft (Money Laundering and Terrorist Financing Prevention Act). This means that we have to check all transactions and report them to the regulator if they are suspicious.`}</li>
    </ul>
    <p>{`Some of the data (communication data) is stored with our suppliers (including Whatsapp, Google, Facebook, Twitter) because we use their services. To the extent possible, we will require this to be stored within the European Economic Area (“EEA”).`}</p>
    <p>{`As you can see, we will do our utmost to keep your personal information secure. We have internal procedures and policies regarding the security of our product to prevent unwanted access.`}</p>
    <h4>{`When do we remove your data?`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Type of information`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`How long do we keep it?`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Why?`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`ID data`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`- 5 years`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`- Legal obligation`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Communication data`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`- 1 year`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`- GDPR`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Identification details`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`- 1 year`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`- GDPR`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Bank information`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`- 5 years`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`- Legal obligation`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Transaction information`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`- 5 years`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`- Legal obligation`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Technical Information`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`- 1 year`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`- GDPR`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Anti Money Laundering (AML)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`- 5 years`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`- Legal obligation`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`Protocol Data Leaks`}</h4>
    <p>{`We have taken strict measures to protect your personal information. In addition, the `}<a parentName="p" {...{
        "href": "https://autoriteitpersoonsgegevens.nl/nl/onderwerpen/beveiliging/acties-bij-datalekken"
      }}>{`Data Leaks Protocol of the AP`}</a>{` is active at Flow. Based on this protocol it is determined whether there is a data breach and under what conditions this must be reported to the Dutch Data Protection Authority (AP). We communicate transparently and honestly about this with you.`}</p>
    <h2>{`6. Sharing your data`}</h2>
    <p>{`We exchange personal information with the following authorities in order to provide Flow services:`}</p>
    <h4>{`Authorities`}</h4>
    <p>{`We are required by law to report unusual transactions to the authorities. We may restrict or exclude you from using Flow based on unusual transactions.`}</p>
    <h4>{`Banks and financial service providers`}</h4>
    <p>{`We can work with these parties to provide services. Our role is to protect your personal data and we will never exchange data without a legitimate reason. Your data will only be sent to these service providers when you have asked us to use their service.`}</p>
    <h4>{`For the accountant`}</h4>
    <p>{`In some cases we may also share data with your accountant. If your accountant uses SnelStart, your accountant can ask for your permission to share data from your Flow account with him or her through our cooperation partner SnelStart.`}</p>
    <p>{`By linking your data in Flow to the SnelStart environment of your accountant, your accountant can optimize their provided service and your Flows. By getting access to your data in Flow, your accountant can also share their own flows with you so that you can make use of them.`}</p>
    <p>{`We only share data with your accountant if you give us permission to do so. The permission you give your accountant via our app can always be easily withdrawn via the Flow app so that your accountant can no longer view the data in your Flow account.`}</p>
    <p>{`By sharing your Flow data with your accountant, your accountant will have insight into your personal Flows, the name of your account(s), IBAN numbers, details of the contra account, amounts and transaction descriptions in his or her SnelStart environment.`}</p>
    <p>{`For more information on the collaboration between Flow and SnelStart `}<a parentName="p" {...{
        "href": "/flow-snelstart-partnership"
      }}>{`visit this page`}</a>{`.`}</p>
    <h4>{`Advertising and analysis service providers`}</h4>
    <p>{`We use a third party to analyze the use of the Site (Google Analytics) and the App (Mixpanel). We do not exchange personal data, but draw up a profile of your use so that we can define different types of user groups. We anonymize your data to protect your privacy.`}</p>
    <h2>{`7. Communication`}</h2>
    <p>{`We believe it is important to explain how we use your data to communicate with you and to state that you have the right to unsubscribe. You can unsubscribe from our mailings at any time by clicking unsubscribe at the bottom of the email, or by contacting `}<a parentName="p" {...{
        "href": "mailto:privacy@flowyour.money"
      }}>{`privacy@flowyour.money`}</a>{`.`}</p>
    <h2>{`8. Your rights`}</h2>
    <p>{`You have rights regarding the personal data we collect. You can contact Flow at `}<a parentName="p" {...{
        "href": "mailto:privacy@flowyour.money"
      }}>{`privacy@flowyour.money`}</a>{` to make your request.`}</p>
    <h4>{`The right to access`}</h4>
    <p>{`You have the right to request access to the personal data that we process. We will provide a copy of the personal data being processed.`}</p>
    <h4>{`The right to rectification`}</h4>
    <p>{`If your details are incorrect, you can request us to adjust the details. We will then correct this.`}</p>
    <h4>{`The right to erasure (oblivion)`}</h4>
    <p>{`By using the services of Flow we process your data. You have the right to ask us to delete this data from our systems. We will comply with this requirement without unreasonable delay, and when it is reasonably within our control.`}</p>
    <p>{`If you no longer use your account, you can contact `}<a parentName="p" {...{
        "href": "mailto:privacy@flowyour.money"
      }}>{`privacy@flowyour.money`}</a>{` to request that your data be removed from our system.`}</p>
    <h4>{`The right to portability (data portability)`}</h4>
    <p>{`You can request us to transfer your personal data in a structured, commonly used and machine-readable format. That way you can transfer your data to a new service provider without any obstacles.`}</p>
    <h4>{`The right to object or to restrict processing`}</h4>
    <p>{`In some cases, you can object to the use of your data or have the right to restrict processing.`}</p>
    <p>{`You have the right to ask us to stop using your personal data in two situations. This is called the right to object. Firstly, when we use your data for direct marketing. Secondly, you can object to the use of your personal data because of your specific situation.`}</p>
    <p>{`The right to restrict processing applies, for example, when your data are incorrect, the processing is unlawful or when the processing of personal data is no longer necessary.`}</p>
    <h4>{`Processing of requests`}</h4>
    <p>{`We will try to fulfil legitimate requests within one month. In exceptional cases, it may take longer than one month, please understand.`}</p>
    <h4>{`If we need your permission`}</h4>
    <p>{`If we process your data on the basis of consent, you always have the right to withdraw your consent. You can do this simply by sending an e-mail to `}<a parentName="p" {...{
        "href": "mailto:privacy@flowyour.money"
      }}>{`privacy@flowyour.money`}</a>{`. If we have no other basis for the data we process on the basis of consent, we will no longer use and delete that data.`}</p>
    <h4>{`Complaints`}</h4>
    <p>{`If you have a complaint about, for example, the way we use your data or how we respond to your privacy-related questions, you can lodge a complaint with the Dutch Data Protection Authority.`}</p>



    <Seo title={props.pageContext.frontmatter.title} path={props.pageContext.frontmatter.path} mdxType="Seo" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      